import {
  Text,
  Badge,
  BadgePriority as BADGE_PRIORITY,
  Button as TpaButton,
  ButtonSize as SIZE,
  Divider,
  Dropdown,
  DropdownOptionProps,
  TextButton,
  SectionNotificationType as NOTIFICATION_TYPE,
  SectionNotification,
} from 'wix-ui-tpa';

import { Button } from '../Button/Button';
import { classes, st } from './BookingDetails.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import React, { useState, useEffect } from 'react';
import CameraSVG from 'wix-ui-icons-common/on-stage/system/VideoFillXSmall';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { BookingDetailsDataHooks } from './BookingDetailsDataHooks';
import { BookingDetailsViewModel } from '../../ViewModel/bookingDetailsViewModel/bookingDetailsViewModel';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import {
  BookingPreference,
  BookingPreferenceOption,
} from '../../../../utils/bookingPreferences/bookingPreferences';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { ReactComponent as AlertIcon } from '../../../../assets/Icons/notificationAlert.svg';

export interface BookingDetailsProps {
  viewModel: BookingDetailsViewModel;
}

const BookingDetails: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const { isMobile } = useEnvironment();
  const { onNext, onSubmit } = useCalendarActions();
  const { experiments } = useExperiments();
  const {
    preferences: { bookingPreferences },
    ctaText,
    ctaFullWidth,
    disableCTAButton,
    alert,
  } = viewModel;
  const isOnSubmitRefactorEnabled = experiments.enabled(
    'specs.bookings.onSubmitRefactor',
  );
  const isButtonComponentEnabled = experiments.enabled(
    'specs.bookings.calendarButtonComponent',
  );

  const shouldShowPreferences = () => {
    return bookingPreferences.some(
      (bookingPreference: BookingPreference) =>
        bookingPreference.options.length > 1,
    );
  };

  return (
    <div
      data-hook={BookingDetailsDataHooks.BOOKING_DETAILS_SELECTION_WRAPPER}
      className={st(classes.root, { isMobile })}
    >
      <TimeSlotDetails viewModel={viewModel} />
      {shouldShowPreferences() ? (
        <BookingPreferences viewModel={viewModel} />
      ) : null}
      <Alert alert={alert} />
      {isButtonComponentEnabled ? (
        <Button
          disabled={disableCTAButton}
          data-hook={BookingDetailsDataHooks.BOOK_BUTTON}
          fullWidth={ctaFullWidth}
          onClick={() => (isOnSubmitRefactorEnabled ? onNext() : onSubmit())}
        >
          {ctaText}
        </Button>
      ) : (
        <TpaButton
          disabled={disableCTAButton}
          size={SIZE.medium}
          className={classes.bookButton}
          data-hook={BookingDetailsDataHooks.DEPRECATED_BOOK_BUTTON}
          upgrade
          fullWidth={ctaFullWidth}
          onClick={() => (isOnSubmitRefactorEnabled ? onNext() : onSubmit())}
        >
          {ctaText}
        </TpaButton>
      )}
    </div>
  );
};
export default BookingDetails;

const Alert = ({ alert }: { alert?: string }) => {
  return alert ? (
    <SectionNotification
      type={NOTIFICATION_TYPE.alert}
      data-hook={BookingDetailsDataHooks.ALERT}
      className={classes.alert}
    >
      <SectionNotification.Icon icon={<AlertIcon />} />
      <SectionNotification.Text>{alert}</SectionNotification.Text>
    </SectionNotification>
  ) : null;
};

const TimeSlotDetails: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const {
    serviceName,
    dateAndTime,
    paymentDescription,
    preferences: { bookingPreferences },
    videoConferenceBadgeText,
  } = viewModel;

  return (
    <>
      <div
        data-hook={BookingDetailsDataHooks.SLOT_DETAILS}
        className={classes.serviceNameAndDate}
      >
        {videoConferenceBadgeText && (
          <Badge
            data-hook={BookingDetailsDataHooks.VIDEO_CONFERENCE_BADGE}
            priority={BADGE_PRIORITY.light}
            icon={<CameraSVG />}
            className={classes.videoConferenceBadge}
          >
            {videoConferenceBadgeText}
          </Badge>
        )}
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_NAME}
          className={classes.commonTitleStyles}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {serviceName}
        </Text>
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_DATE_AND_TIME}
          className={classes.commonTitleStyles}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {dateAndTime}
        </Text>
      </div>
      {bookingPreferences.map((bookingPreference) => {
        return bookingPreference.options.length === 1 ? (
          <Text
            data-hook={`${BookingDetailsDataHooks.TIME_SLOT_DETAILS}-${bookingPreference.key}`}
            className={classes.commonTextStyle}
            tagName={AccessibilityHtmlTags.Paragraph}
            aria-label={bookingPreference.options[0].ariaLabel}
          >
            {bookingPreference.options[0].value}
          </Text>
        ) : null;
      })}
      <Text
        data-hook={BookingDetailsDataHooks.SLOT_PLAN_TYPE}
        className={classes.commonTextStyle}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {paymentDescription}
      </Text>
    </>
  );
};

const BookingPreferences: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const {
    onBookingPreferenceOptionSelected,
    onClearSelectedBookingPreferences,
    onElementClicked,
  } = useCalendarActions();
  const [forceDropdownRender, setForceDropdownRender] = useState(false);
  const [isClearButtonVisible, setClearButtonVisibility] = useState(false);
  const [isPreselectedIdAllowed, setPreselectedIdAllowed] = useState(false);
  const [selectedDropDown, setSelectedDropDown] = useState<BookingPreference>();
  const { dateAndTime, preferences } = viewModel;
  const { bookingPreferences, titleText, clearText } = preferences;
  const { experiments } = useExperiments();

  const isDynamicPricingUoUEnabled = experiments.enabled(
    'specs.bookings.dynamicPricingUoU',
  );

  useEffect(() => {
    setClearButtonVisibility(false);
    setPreselectedIdAllowed(false);
  }, [dateAndTime]);

  const clearDropdowns = () => {
    setForceDropdownRender((currentForceRender) => !currentForceRender);
    onClearSelectedBookingPreferences();
    setClearButtonVisibility(false);
    setPreselectedIdAllowed(false);
  };

  const onDropdownChange = (
    selectedOption: DropdownOptionProps,
    bookingPreference: BookingPreference,
  ) => {
    onBookingPreferenceOptionSelected({
      key: bookingPreference.key,
      value: selectedOption.id!,
    });
    setClearButtonVisibility(true);
    setPreselectedIdAllowed(true);
  };

  const onDropdownClick = (bookingPreference: BookingPreference) => {
    onElementClicked(
      WidgetComponents.BOOKING_DETAILS,
      WidgetElements.DROPDOWN,
      bookingPreference.key,
    );
    setSelectedDropDown(bookingPreference);
  };

  const getDropdownOptionsForBookingPreference = (
    bookingPreference: BookingPreference,
  ): DropdownOptionProps[] => {
    const dropdownSubtitle = {
      id: bookingPreference.placeholder,
      value: bookingPreference.placeholder,
      isSelectable: false,
      isSectionTitle: true,
    };

    let dropdownOptions = bookingPreference.options;
    if (isDynamicPricingUoUEnabled) {
      dropdownOptions = mapBookingPreferenceOptionsToDropdownOptions(
        bookingPreference.options,
      );
    }

    return [dropdownSubtitle, ...dropdownOptions];
  };

  const mapBookingPreferenceOptionsToDropdownOptions = (
    bookingPreferenceOptions: BookingPreferenceOption[],
  ): DropdownOptionProps[] => {
    return bookingPreferenceOptions.map(({ price, ...rest }) => ({
      ...rest,
      subtitle: price,
    }));
  };

  return (
    <div data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_WRAPPER}>
      <Divider
        className={classes.divider}
        data-hook={BookingDetailsDataHooks.DIVIDER}
      />
      <div className={classes.bookingPreferences}>
        <p className={classes.preferencesTitle}>
          <Text
            data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_TITLE}
            className={classes.commonTitleStyles}
            tagName={AccessibilityHtmlTags.Paragraph}
          >
            {titleText}
          </Text>
        </p>
        {bookingPreferences.map((bookingPreference: BookingPreference) => {
          if (bookingPreference.options.length > 1) {
            return (
              <Dropdown
                mobileNativeSelect={!isDynamicPricingUoUEnabled}
                initialSelectedId={
                  isPreselectedIdAllowed
                    ? bookingPreference.preselectedOptionId
                    : '-1' // WA since ui-tpa does not recognize switching from value to undefined as reset
                }
                error={!!bookingPreference.error.message}
                errorMessage={bookingPreference.error.message}
                data-hook={
                  BookingDetailsDataHooks.DROPDOWN + '-' + bookingPreference.key
                }
                className={st(classes.commonDropDownStyle, {
                  aboveAll: selectedDropDown === bookingPreference,
                })}
                aria-label={bookingPreference.placeholder}
                placeholder={bookingPreference.placeholder}
                options={getDropdownOptionsForBookingPreference(
                  bookingPreference,
                )}
                onExpandedChange={() => onDropdownClick(bookingPreference)}
                key={dateAndTime + bookingPreference.key + forceDropdownRender}
                onChange={(selectedOption) =>
                  onDropdownChange(selectedOption, bookingPreference)
                }
              />
            );
          }
          return null;
        })}
        {isClearButtonVisible ? (
          <p className={classes.clearPreferences}>
            <TextButton
              data-hook={BookingDetailsDataHooks.CLEAR_BUTTON}
              className={classes.commonTitleStyles}
              onClick={() => clearDropdowns()}
            >
              <span className={classes.textWithEllipsis}>{clearText}</span>
            </TextButton>
          </p>
        ) : null}
      </div>
    </div>
  );
};

// eslint-disable-next-line no-shadow
export enum DataHooks {
  NoAvailableSlots = 'no-available-slots-wrapper',
  Text = 'no-available-slots-text',
  Notification = 'no-available-slots-notification',
  Link = 'no-available-slots-link',
  CTA = 'no-available-slots-cta',
  /* TODO: delete when specs.bookings.calendarButtonComponent is terminated */
  DEPRECATED_CTA = 'deprecated-no-available-slots-cta',
}

// eslint-disable-next-line no-shadow
export enum BookingDetailsDataHooks {
  BOOKING_DETAILS_SELECTION_WRAPPER = 'booking-details-wrapper',
  TIME_SLOT_DETAILS = 'time-slot-details',
  SLOT_NAME = 'slot-name',
  SLOT_DETAILS = 'slot-details',
  SLOT_DATE_AND_TIME = 'slot-date-and-time',
  SLOT_PLAN_TYPE = 'slot-plan-type',
  DIVIDER = 'divider',
  SLOT_PREFERENCES_WRAPPER = 'slot-preferences-wrapper',
  SLOT_PREFERENCES_TITLE = 'slot-preferences-title',
  CLEAR_BUTTON = 'clear-button',
  VIDEO_CONFERENCE_BADGE = 'video-conference-badge',
  BOOK_BUTTON = 'book-button',
  /* TODO: delete when specs.bookings.calendarButtonComponent is terminated */
  DEPRECATED_BOOK_BUTTON = 'deprecated-book-button',
  DROPDOWN = 'dropdown',
  ALERT = 'alert',
}
